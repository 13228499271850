// @flow
import React from 'react'
import Story from 'storybook/Story'
import Redux from 'containers/Redux'
import GlobalStyle from 'containers/GlobalStyle'
import { graphql } from 'gatsby'

type Props = {
  location: { key: string }
  data: {
    page: { story: any }
  }
}

export default function StoryOnlyPage(props: Props) {
  const { story } = props.data.page
  return (
    <Redux partialStateUpdates={story.partialStateUpdates}>
      <GlobalStyle />
      <Story key={props.location.key} story={story} />
    </Redux>
  )
}

export const query = graphql`
  query($objectID: String!) {
    page(objectID: { eq: $objectID }) {
      story
    }
  }
`
